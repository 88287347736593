import { storage, tokenTableName } from '@/config';
import cookie from 'js-cookie';

/**
 * @author Harlan luoxwen@gmail.com
 * @description 获取accessToken
 * @returns {string|Promise<any>|any|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getAccessToken() {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.getItem(tokenTableName);
    } if (storage === 'sessionStorage') {
      return sessionStorage.getItem(tokenTableName);
    } if (storage === 'cookie') {
      return cookie.get(tokenTableName);
    }
    return localStorage.getItem(tokenTableName);
  }
  return localStorage.getItem(tokenTableName);
}

/**
 * @author Harlan luoxwen@gmail.com
 * @description 存储accessToken
 * @param accessToken
 * @returns {void|*}
 */
export function setAccessToken(accessToken) {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.setItem(tokenTableName, accessToken);
    } if (storage === 'sessionStorage') {
      return sessionStorage.setItem(tokenTableName, accessToken);
    } if (storage === 'cookie') {
      return cookie.set(tokenTableName, accessToken);
    }
    return localStorage.setItem(tokenTableName, accessToken);
  }
  return localStorage.setItem(tokenTableName, accessToken);
}

/**
 * @author Harlan luoxwen@gmail.com
 * @description 移除accessToken
 * @returns {void|Promise<void>}
 */
export function removeAccessToken() {
  if (storage) {
    if (storage === 'localStorage') {
      return localStorage.removeItem(tokenTableName);
    } if (storage === 'sessionStorage') {
      return sessionStorage.clear();
    } if (storage === 'cookie') {
      return cookie.remove(tokenTableName);
    }
    return localStorage.removeItem(tokenTableName);
  }
  return localStorage.removeItem(tokenTableName);
}
