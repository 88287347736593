import { constantRouterMap, asyncRoutes } from '@/router/router.config';
import { generatorDynamicRouter } from '@/router/async.route';
import { cloneDeep } from 'lodash-es';

/**
 * 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
function hasPermission(permission, route) {
  if (route.meta && route.meta.permission && permission) {
    let flag = false;
    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = permission.length; i < len; i++) {
      flag = route.meta.permission.includes(permission[i]);
      if (flag) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function filterAsyncRouter(routerMap, permissonCodes) {
  return routerMap.filter((route) => {
    if (hasPermission(permissonCodes, route)) {
      if (route.children && route.children.length) {
        // eslint-disable-next-line no-param-reassign
        route.children = filterAsyncRouter(route.children, permissonCodes);
      }
      return true;
    }
    return false;
  });
}

const actions = {
  // 后端接口返回路由
  GenerateRoutes({ commit }) {
    return new Promise((resolve) => {
      generatorDynamicRouter().then((routers) => {
        commit('SET_ROUTERS', routers);
        resolve();
      });
    });
  },
  // 前端控制路由
  intelligenceRoutes({ commit }, data) {
    return new Promise((resolve) => {
      const datas = cloneDeep(asyncRoutes);
      const accessedRouters = filterAsyncRouter(datas, data);
      commit('SET_ROUTERS', accessedRouters);
      resolve();
    });
  },
};

const mutations = {
  SET_ROUTERS: (state, routers) => {
    state.addRouters = routers;
    state.routers = constantRouterMap.concat(routers);
  },
};

const getters = {
  addRouters: (state) => state.addRouters,
};

const state = () => ({
  routers: constantRouterMap,
  addRouters: [],
});

export default {
  state, getters, mutations, actions,
};
