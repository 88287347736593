import axios from 'axios';
import {
  contentType,
  debounce,
  requestTimeout,
  successCode,
} from '@/config';
import store from '@/store';
import qs from 'qs';
import router from '@/router';
import { isArray } from '@/utils/validate';
import { message } from 'ant-design-vue';

let loadingInstance;

/**
 * @author Harlan luoxwen@gmail.com
 * @description 处理code异常
 * @param {*} code
 * @param {*} msg
 */
const handleCode = (code, msg) => {
  switch (code) {
    case 401:
      store.dispatch('user/resetAll').then(() => {
        router.push({ name: 'Login' });
      });
      break;
    case 402:
      store.dispatch('user/resetAll').then(() => {
        router.push({ name: 'Login' });
      });
      break;
    case 403:
      router.push({ path: '/401' }).catch(() => {});
      break;
    default:
      message.error(msg || `后端接口${code}异常`);
      break;
  }
};

/**
 * @author Harlan luoxwen@gmail.com
 * @description axios初始化
 */
const instance = axios.create({
  baseURL: '/api',
  timeout: requestTimeout,
  headers: {
    'Content-Type': contentType,
  },
});

/**
 * @author Harlan luoxwen@gmail.com
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    if (store.getters['user/accessToken']) {
      config.headers.Authorization = store.getters['user/accessToken'];
    }
    if (
      config.data
      && config.headers['Content-Type']
        === 'application/x-www-form-urlencoded;charset=UTF-8'
    ) {
      config.data = qs.stringify(config.data);
    }
    if (debounce.some((item) => config.url.includes(item))) {
      // 这里写加载动画
    }
    return config;
  },
  (error) => Promise.reject(error),
);

/**
 * @author Harlan luoxwen@gmail.com
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
  (response) => {
    if (loadingInstance) loadingInstance.close();

    const { data, config } = response;
    // eslint-disable-next-line no-shadow
    const { code, message } = data;
    // 操作正常Code数组
    const codeVerificationArray = isArray(successCode)
      ? [...successCode]
      : [...[successCode]];
    // 是否操作正常
    if (codeVerificationArray.includes(code)) {
      return data;
    }

    // 导出订单，返回只有文件
    if (!code && response.status === 200) {
        return response.data
    }

    handleCode(code, message);
    return Promise.reject(
      `请求异常拦截:${
        JSON.stringify({ url: config.url, code, message })}` || 'Error',
    );
  },
  (error) => {
    if (loadingInstance) loadingInstance.close();
    const { response } = error;
    let { message: msg } = error;
    if (error.response && error.response.data) {
      const { code, data } = response;
      handleCode(code, data.message || msg);
      return Promise.reject(error);
    }

    if (msg === 'Network Error') {
      msg = '后端接口连接异常';
    }
    if (msg.includes('timeout')) {
      msg = '后端接口请求超时';
    }
    if (msg.includes('Request failed with status code')) {
      const code = msg.substr(msg.length - 3);
      msg = `后端接口${code}异常`;
    }
    message.error(msg || '后端接口未知异常');
    return Promise.reject(error);
  },
);

export default instance;
