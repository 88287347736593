// 动态路由
export const asyncRoutes = [
  {
    name: 'layout',
    component: () => import('@/layout/index'),
    path: '/',
    redirect: '/welcome',
    children: [
      // 欢迎页，不需要任何权限
      {
        path: '/welcome',
        name: 'Welcome',
        component: () => import('@/views/Index'),
        meta: { show: false },
      },

      // 数据统计
      {
        path: '/statistics',
        name: 'statistics',
        component: () => import('@/views/statistics/Index'),
        meta: { title: 'route.statistics', icon: 'bar-chart-box-line', permission: ['dashboard_menu.view'], show: true },
      },

      // 审核认证
      {
        path: '/audit',
        name: 'audit',
        component: () => import('@/layout/RouterView'),
        redirect: '/audit/workbench',
        meta: { title: 'route.audit', icon: 'mail-check-line', permission: ['approve_menu.view'], show: true },
        children: [
          {
            name: 'workbench',
            path: '/audit/workbench',
            component: () => import('@/views/audit/Workbench'),
            meta: { title: 'route.workbench', permission: ['approve_workbench.view'], show: true },
          },
          {
            name: 'companyAudit',
            path: '/audit/company',
            component: () => import('@/layout/RouterView'),
            redirect: '/audit/company/list',
            meta: { title: 'route.company', permission: ['approve_company.view'], show: true },
            children: [
              {
                name: 'companyAuditList',
                path: '/audit/company/list',
                component: () => import('@/views/audit/user/Company'),
                meta: { title: 'route.company', show: false },
              },
              {
                name: 'companyAuditDetail',
                path: '/audit/company/detail',
                component: () => import('@/views/audit/user/CompanyDetail'),
                meta: { title: 'route.companyDetail', show: false },
              },
            ],
          },
          {
            name: 'author',
            path: '/audit/author',
            component: () => import('@/layout/RouterView'),
            redirect: '/audit/author/personal',
            meta: { title: 'route.author', permission: ['approve_author_menu.view'], show: true },
            children: [
              {
                name: 'authorPersonal',
                path: '/audit/author/personal',
                component: () => import('@/views/audit/user/AuthorPersonal'),
                meta: { title: 'route.personalAuthor', permission: ['approve_author_personal.view'], show: true },
              },
              {
                name: 'authorCompany',
                path: '/audit/author/company',
                component: () => import('@/views/audit/user/AuthorCompany'),
                meta: { title: 'route.companyAuthor', permission: ['approve_author_company.view'], show: true },
              },
              {
                name: 'authorAuditDetail',
                path: '/audit/author/detail',
                component: () => import('@/views/audit/user/AuthorDetail'),
                meta: { title: 'route.authorApplyDetail', show: false },
              },
            ],
          },
          {
            name: 'goodsList',
            path: '/audit/goods/list',
            component: () => import('@/views/audit/Goods'),
            meta: { title: 'route.goods', permission: ['approve_goods.view'], show: true },
          },
          {
            name: 'goodsDetail',
            path: '/audit/goods/detail',
            component: () => import('@/views/audit/GoodsDetail'),
            meta: { title: 'route.goodsDetail', show: false },
          },
        ],
      },

      // 用户管理
      {
        path: '/user',
        name: 'userManagement',
        component: () => import('@/layout/RouterView'),
        redirect: '/user/company',
        meta: { title: 'route.users', icon: 'user-received-line', permission: ['user_menu.view'], show: true },
        children: [
          {
            path: '/user/company',
            name: 'companyUserList',
            component: () => import('@/views/users/Company'),
            meta: { title: 'route.companyUser', permission: ['user_company.view'], show: true },
          },
          {
            path: '/user/company/detail',
            name: 'companyUserDetail',
            component: () => import('@/views/users/CompanyDetail'),
            meta: { title: 'route.companyUser', show: false },
          },
          {
            path: '/user/personal',
            name: 'personalUserList',
            component: () => import('@/views/users/Personal'),
            meta: { title: 'route.personalUser', permission: ['user_personal.view'], show: true },
          },
          {
            path: '/user/personal/detail',
            name: 'personalUserDetail',
            component: () => import('@/views/users/PersonalDetail'),
            meta: { title: 'route.personalUser', show: false },
          },
        ],
      },

      // nft作品管理
      {
        path: '/nft',
        name: 'nft',
        component: () => import('@/layout/RouterView'),
        redirect: '/nft/series',
        meta: { title: 'route.nft', icon: 'image-edit-line', permission: ['goods.view'], show: true },
        children: [
          {
            path: '/nft/series',
            name: 'nftList',
            component: () => import('@/views/nft/Index'),
            meta: { title: '作品列表', show: false },
          },
          {
            path: '/nft/works',
            name: 'works',
            component: () => import('@/views/nft/Works'),
            meta: { title: '作品列表', show: false },
          },
          {
            path: '/nft/works/detail',
            name: 'worksDetail',
            component: () => import('@/views/nft/Detail'),
            meta: { title: '作品详情', show: false },
          },
        ],
      },

      // 订单管理
      {
        path: '/order',
        name: 'order',
        component: () => import('@/layout/RouterView'),
        redirect: '/order/list',
        meta: { title: 'route.order', icon: 'list-unordered', permission: ['order.view'], show: true },
        children: [
          {
            path: '/order/list',
            name: 'orderList',
            component: () => import('@/views/order/Order'),
            meta: { title: 'route.order', show: false },
          },
          {
            path: '/order/detail',
            name: 'orderDetail',
            component: () => import('@/views/order/Detail'),
            meta: { title: '订单详情页', show: false },
          },
        ],
      },

      // 系统设置
      {
        path: '/setting',
        name: 'setting',
        component: () => import('@/layout/RouterView'),
        redirect: '/setting/banner',
        meta: { title: 'route.setting', icon: 'settings-2-line', permission: ['system_menu.view'], show: true },
        children: [
          {
            path: '/setting/banner',
            name: 'setting/banner',
            component: () => import('@/views/setting/banner/Index'),
            meta: { title: 'route.banner', permission: ['system_banner.view'], show: true },
          },
        ],
      },

      // 组织架构
      {
        path: '/organization',
        name: 'organization',
        component: () => import('@/layout/RouterView'),
        redirect: '/organization/members',
        meta: { title: 'route.organization', icon: 'user-location-line', permission: ['organ_menu.view'], show: true },
        children: [
          {
            path: '/organization/members',
            name: 'organization/members',
            component: () => import('@/views/organization/members/Index'),
            meta: { title: 'route.members', permission: ['organ_admin.view'], show: true },
          },
          {
            path: '/organization/roles',
            name: 'organization/roles',
            component: () => import('@/views/organization/roles/Index'),
            meta: { title: 'route.role', permission: ['organ_role.view'], show: true },
          },
        ],
      },

      // 个人中心
      {
        path: '/ucenter',
        name: 'ucenter',
        component: () => import('@/views/user/Index.vue'),
        meta: { title: 'navbar.profile', icon: 'user-line', permission: ['mine_menu.view'], show: true },
      },

      // 提现
      {
        path: '/withdraw',
        name: 'withdraw',
        redirect: '/withdraw/list',
        component: () => import('@/layout/RouterView'),
        meta: { title: 'navbar.withdraw', icon: 'money-cny-box-line', permission: ['withdraw_menu.view'], show: true },
        children: [
          {
            path: '/withdraw/list',
            name: 'withdraw',
            component: () => import('@/views/withdraw/Index.vue'),
            meta: { title: 'navbar.withdraw', icon: 'money-cny-box-line', permission: ['withdraw_menu.view'], show: false },
          },
          {
            path: '/withdraw/detail',
            name: 'withdrawDetail',
            component: () => import('@/views/withdraw/Detail.vue'),
            meta: { title: '提现详情', icon: 'money-cny-box-line', show: false },
          },
        ],
      },

      // APP钱包升级管理
      {
        path: '/app',
        name: 'app',
        component: () => import('@/views/app/Index.vue'),
        meta: { title: 'route.app', icon: 'money-cny-box-line', permission: ['app.view'], show: true },
      },
    ],
  },
];

// 不需要授权的路由建议放在这里面
export const constantRouterMap = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/user/login.vue'),
  },
];
