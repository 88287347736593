import store from '@/store';

/**
 * vue3 Action 权限指令
 * 指令用法：
 *  - 在需要控制 action 级别权限的组件上使用 v-action:[method] , 如下：
 *    <i-button v-action:add >添加用户</a-button>
 *    <a-button v-action:delete>删除用户</a-button>
 */
export default (app) => {
  app.directive('action', (el, binding) => {
    const actionName = binding.arg;
    // mock数据结构的写法，具体业务具体实现
    const roles = store.getters['user/roles'];
    roles.permissions.forEach(p => {
      if (!p.includes(actionName)) {
        // eslint-disable-next-line no-unused-expressions
        (el.parentNode && el.parentNode.removeChild(el)) || (el.style.display = 'none');
      }
    });
  });
};
