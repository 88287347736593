import { createApp } from 'vue';
import { message } from 'ant-design-vue';
import NProgress from 'nprogress'
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './locales';
import './assets/global.css';
import 'ant-design-vue/dist/antd.css';

// 权限校验，测试用，如不需要可自行删除
import './permission';

// 核心依赖组件按需加载: antd
import lazy from './core/lazyload';

// 自定义权限指令
import directives from './core/action';

// 权限校验插件
import auth from './core/permission';

// 导入进度条插件
import 'nprogress/nprogress.css'

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  const div = document.querySelector('.ant-layout-box');
  if (div) div.scrollTop = 0;
  NProgress.done()
})

const app = createApp(App).use(i18n).use(store).use(router)
  .use(auth);

app.config.globalProperties.$message = message;

lazy(app);
directives(app);

// DEV TOOL 不显示的问题处理
if (process.env.NODE_ENV === 'development') {
  if ('__VUE_DEVTOOLS_GLOBAL_HOOK__' in window) {
    // 这里__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue赋值一个createApp实例
    // 调试工具显示后可以注释调这段代码，不然调试工具会多出一个vue实例
    // eslint-disable-next-line no-underscore-dangle
    window.__VUE_DEVTOOLS_GLOBAL_HOOK__.Vue = app;
  }
  app.config.devtools = true;
}

app.mount('#app');
