import notification from 'ant-design-vue/es/notification';
import defaultSettings from '@/config';
import store from './store';
import router from './router';

const { authentication, tokenName } = defaultSettings;

// 不需要授权的白名单列表，不需要token也可跳转页面
const allowList = ['Login', 'register'];

const loginRoutePath = '/login';
const defaultRoutePath = '/';

router.beforeEach((to, from, next) => {
  if (localStorage.getItem(tokenName)) {
    if (to.path === loginRoutePath) {
      next({ path: defaultRoutePath });
    } else if (!store.getters['user/userInfo'].id) {
      store
        .dispatch('user/getUserInfo')
        .then((res) => {
          const { permissions } = res;

          // 前端控制路由
          store.dispatch('router/intelligenceRoutes', permissions).then(() => {
            store.getters['router/addRouters'].forEach((val) => {
              router.addRoute(val);
            });
            const redirect = decodeURIComponent(from.query.redirect || to.path);
            if (to.path === redirect) {
              next({ ...to, replace: true });
            } else {
              next({ path: redirect });
            }
          });
        })
        .catch(() => {
          notification.error({
            message: '错误',
            description: '请求用户信息失败，请重试',
          });
          // 失败时，获取用户信息失败时，调用登出，来清空历史保留信息
          store.dispatch('user/logout').then(() => {
            next({ path: loginRoutePath, query: { redirect: to.fullPath } });
          });
        });
    } else {
      next();
    }
  } else if (allowList.includes(to.name)) {
    // 在免登录名单，直接进入
    next();
  } else {
    next({ path: loginRoutePath, query: { redirect: to.fullPath } });
  }
});
