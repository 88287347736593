/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
export function isJson(value) {
  if (typeof value === 'string') {
    try {
      const obj = JSON.parse(value);
      if (typeof obj === 'object' && obj) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  }
}

/**
 * @author Harlan luoxwen@gmail.com
 * @description 判断是否是数组
 * @param arg
 * @returns {arg is any[]|boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}

// 验证手机号
export function checkPhone(n) {
  return (/^1([3456789])\d{9}$/.test(n))
}
