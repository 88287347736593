import { createI18n } from 'vue-i18n/index';
import { getLanguage } from '@/utils/cookies';

// default lang
import zhCN from './lang/zh-CN';
import enUS from './lang/en-US';

const messages = {
  zh: {
    ...zhCN,
  },
  en: {
    ...enUS,
  },
};

// 获取当前使用的语言
export const getLocale = () => {
  const cookieLanguage = getLanguage();
  if (cookieLanguage) {
    return cookieLanguage;
  }
  const language = navigator.language.toLowerCase();

  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale.toLowerCase()) > -1) {
      return locale;
    }
  }

  return 'zh';
};

const i18n = createI18n({
  locale: getLocale(),
  messages,
});

export default i18n;
