import { createRouter, createWebHashHistory } from 'vue-router';
import { constantRouterMap } from './router.config';

const router = createRouter({
  // 管理平台无需考虑 SEO 路径的问题，启用 hash 模式
  history: createWebHashHistory(),
  routes: constantRouterMap,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
});

export default router;
