/*
* 用户信息mock数据，后续需要根据mock服务那边的数据结构进行调整
* */
export const userInfo = {
  id: '12345678ytgfdsdsaewd',
  name: '谭刘彬',
  username: 'admin',
  password: '',
  avatar: 'https://picsum.photos/50/50',
  status: 1,
  telephone: '',
  lastLoginTime: 1534837621348,
  creatorId: 'admin',
  createTime: 1497160610259,
  merchantCode: 'TLif2btpzg079h15bk',
  deleted: 0,
  roleId: 'admin',
  role: {
    id: 'admin',
    name: '管理员',
    describe: '拥有所有权限',
    status: 1,
    creatorId: 'system',
    createTime: 1497160610259,
    deleted: 0,
    permissions: [
      {
        roleId: 'admin',
        permissionId: 'dashboard',
        permissionName: '仪表盘',
        actions: '[{"action":"add","defaultCheck":false,"describe":"新增"},{"action":"query","defaultCheck":false,"describe":"查询"},{"action":"get","defaultCheck":false,"describe":"详情"},{"action":"update","defaultCheck":false,"describe":"修改"},{"action":"delete","defaultCheck":false,"describe":"删除"}]',
        actionEntitySet: [{
          action: 'add',
          describe: '新增',
          defaultCheck: false,
        }, {
          action: 'query',
          describe: '查询',
          defaultCheck: false,
        }, {
          action: 'get',
          describe: '详情',
          defaultCheck: false,
        }, {
          action: 'update',
          describe: '修改',
          defaultCheck: false,
        }, {
          action: 'delete',
          describe: '删除',
          defaultCheck: false,
        }],
        actionList: null,
        dataAccess: null,
      },
    ],
  },
};

/*
* 用户路由mock数据，后续需要根据mock服务那边的数据结构进行调整
* icon 统一使用 http://www.remixicon.cn/
* */
export const userRoute = {
  code: 0,
  msg: 'success',
  data: [
    {
      name: 'layout',
      id: 1,
      parentId: -1,
      component: 'Layout',
      path: '/',
      redirect: '/index/home',
      children: [
        {
          path: '/index',
          name: 'index',
          component: 'RouterView',
          redirect: '/index/home',
          meta: { title: 'route.overview', icon: 'function-line' },
          children: [
            {
              path: '/index/home',
              name: 'overview',
              component: 'index/Home',
              meta: { title: 'route.home' },
            },
            {
              path: '/index/dashboard',
              name: 'dashboard',
              component: 'index/Test',
              meta: { title: 'route.dashboard' },
            },
          ],
        },
        {
          path: '/personalization',
          name: 'personalization',
          component: 'RouterView',
          redirect: '/personalization/site',
          meta: { title: 'route.personalization', icon: 't-shirt-2-line' },
          children: [
            {
              path: '/personalization/site',
              name: 'personalization/site',
              component: 'index/Test',
              meta: { title: 'route.site' },
            },
            {
              path: '/personalization/theme',
              name: 'personalization/theme',
              component: 'index/Home',
              meta: { title: 'route.theme' },
            },
          ],
        },
        {
          path: '/organization',
          name: 'organization',
          component: 'RouterView',
          redirect: '/organization/members',
          meta: { title: 'route.organization', icon: 'user-location-line' },
          children: [
            {
              path: '/organization/members',
              name: 'organization/members',
              component: 'index/Home',
              meta: { title: 'route.members' },
            },
            {
              path: '/organization/roles',
              name: 'organization/roles',
              component: 'index/Home',
              meta: { title: 'route.role' },
            },
            {
              path: '/organization/addressBook',
              name: 'organization/addressBook',
              component: 'index/Home',
              meta: { title: 'route.addressBook' },
            },
            {
              path: '/organization/customize',
              name: 'organization/customize',
              component: 'index/Home',
              meta: { title: 'route.customize' },
            },
          ],
        },
        {
          path: '/security',
          name: 'security',
          component: 'RouterView',
          redirect: '/security/permission',
          meta: { title: 'route.securityStrategy', icon: 'shield-flash-line' },
          children: [
            {
              path: '/security/permission',
              name: 'security/permission',
              component: 'index/Home',
              meta: { title: 'route.permission' },
            },
            {
              path: '/security/logs',
              name: 'security/logs',
              component: 'index/Home',
              meta: { title: 'route.logs' },
            },
          ],
        },
        {
          path: '/setting',
          name: 'setting',
          component: 'RouterView',
          redirect: '/setting/account',
          meta: { title: 'route.setting', icon: 'settings-2-line' },
          children: [
            {
              path: '/setting/account',
              name: 'setting/account',
              component: 'index/Home',
              meta: { title: 'route.account' },
            },
            {
              path: '/setting/admin',
              name: 'setting/admin',
              component: 'index/Home',
              meta: { title: 'route.adminPermission' },
            },
          ],
        },
        {
          path: '/developer',
          name: 'developer',
          component: 'RouterView',
          redirect: '/developer/menu',
          meta: { title: 'route.developer', icon: 'terminal-box-line' },
          children: [
            {
              path: '/developer/menu',
              name: 'developer/menu',
              component: 'index/Home',
              meta: { title: 'route.menu' },
            },
            {
              path: '/developer/layout',
              name: 'developer/layout',
              component: 'index/Home',
              meta: { title: 'route.layout' },
            },
            {
              path: '/developer/components',
              name: 'developer/components',
              component: 'index/Home',
              meta: { title: 'route.components' },
            },
            {
              path: '/developer/resources',
              name: 'developer/resources',
              component: 'index/Home',
              meta: { title: 'route.resources' },
            },
            {
              path: '/developer/system',
              name: 'developer/system',
              component: 'index/Home',
              meta: { title: 'route.system' },
            },
          ],
        },
      ],
    },
  ],
};
