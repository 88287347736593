/**
 * @author Harlan luoxwen@gmail.com
 * @description 登录、获取用户信息、退出登录、清除accessToken逻辑，不建议修改
 */
import { getUserInfo, login } from '@/api/user';
import {
  getAccessToken,
  removeAccessToken,
  setAccessToken,
} from '@/utils/accessToken';
import { title, tokenName } from '@/config';
import { message, notification } from 'ant-design-vue';

const getters = {
  accessToken: (state) => state.accessToken,
  username: (state) => state.username,
  avatar: (state) => state.avatar,
  roles: (state) => state.roles,
  userInfo: (state) => state.userInfo,
};
const mutations = {
  /**
   * @author Harlan luoxwen@gmail.com
   * @description 设置accessToken
   * @param {*} state
   * @param {*} accessToken
   */
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
    setAccessToken(accessToken);
  },
  /**
   * @author Harlan luoxwen@gmail.com
   * @description 设置用户名
   * @param {*} state
   * @param {*} username
   */
  setUsername(state, username) {
    state.username = username;
  },
  /**
   * @author Harlan luoxwen@gmail.com
   * @description 设置头像
   * @param {*} state
   * @param {*} avatar
   */
  setAvatar(state, avatar) {
    state.avatar = avatar;
  },

  setRoles(state, roles) {
    state.roles = roles;
  },
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
};
const actions = {
  /**
   * @author Harlan luoxwen@gmail.com
   * @description 登录拦截放行时，设置虚拟角色
   * @param {*} { commit, dispatch }
   */
  setVirtualRoles({ commit, dispatch }) {
    dispatch('acl/setFull', true, { root: true });
    commit('setAvatar', 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif');
    commit('setUsername', 'admin(未开启登录拦截)');
  },

  /**
   * @author Harlan luoxwen@gmail.com
   * @description 登录
   * @param {*} { commit }
   * @param {*} userInfo
   */
  async login({ commit, dispatch }, userInfo) {
    const { data } = await login(userInfo);
    const accessToken = data[tokenName];
    if (accessToken) {
      commit('setAccessToken', accessToken);
      localStorage.setItem('ACCESS_TOKEN', accessToken);
      const hour = new Date().getHours();
      const thisTime = hour < 8
        ? '早上好'
        : hour <= 11
          ? '上午好'
          : hour <= 13
            ? '中午好'
            : hour < 18
              ? '下午好'
              : '晚上好';
      notification.open({
        message: `欢迎登录${title}`,
        description: `${thisTime}！`,
      });
    } else {
      message.error(`登录接口异常，未正确返回${tokenName}...`);
    }
  },

  /**
   * @author Harlan luoxwen@gmail.com
   * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
   * @param {*} { commit, dispatch, state }
   * @returns
   */
  async getUserInfo({ commit, state, dispatch, router }) {
    const { data } = await getUserInfo(state.accessToken);
    if (!data) {
      message.error('验证失败，请重新登录...');
      return false;
    }
    const { name, permissions } = data;

    commit('setUsername', name);
    commit('setUserInfo', data);
    // todo 临时加的，后续需要根据接口返回自行修改
    commit('setRoles', data);
    return data;
  },

  /**
   * @author Harlan luoxwen@gmail.com
   * @description 退出登录
   * @param {*} { dispatch }
   */
  async logout({ dispatch }) {
    // await logout(state.accessToken);
    await dispatch('resetAll');
  },
  /**
   * @author Harlan luoxwen@gmail.com
   * @description 重置accessToken、roles、ability、router等
   * @param {*} { commit, dispatch }
   */
  async resetAll({ commit }) {
    commit('setUsername', null);
    commit('setUserInfo', {});
    commit('setRoles', []);
    commit('setAccessToken', null);
    removeAccessToken();
  },
  /**
   * @author Harlan luoxwen@gmail.com
   * @description 设置token
   */
  setAccessToken({ commit }, accessToken) {
    commit('setAccessToken', accessToken);
  },
};

const state = () => ({
  accessToken: getAccessToken(),
  username: '',
  avatar: '',
  roles: [],
  userInfo: {},
});
export default {
  state,
  getters,
  mutations,
  actions,
};
