import { userRoute } from './mock.route';

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '/:pathMatch(.*)*',
  redirect: '/404',
};

/*
* 格式化树形结构数据 生成 vue-router 层级路由表
* @param routerMap
* @param parent
* @returns {*}
* */
export function convertRoutes(routes, parent) {
  if (!routes && routes.length) return [];
  return routes.map((route) => {
    const {
      title, show, icon, permission,
    } = route.meta || {};
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: route.path || `${(parent && parent.path) || ''}/${route.name}`,

      // 路由名称，建议唯一
      name: route.name || '',

      // 该路由对应页面的 组件(动态加载)
      component: route.component === 'Layout'
        ? () => import('@/layout/index.vue')
        : route.component === 'RouterView'
          ? () => import('@/layout/RouterView.vue')
          : () => import(`@/views/${route.component}`),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title,
        icon: icon || undefined,
        permission,
      },
    };

    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/');
    }
    // 重定向
    // eslint-disable-next-line no-unused-expressions
    route.redirect && (currentRouter.redirect = route.redirect);

    // 是否有子菜单，并递归处理
    if (route.children && route.children.length > 0) {
      currentRouter.children = convertRoutes(route.children, currentRouter);
    }
    return currentRouter;
  });
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach((item) => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: item.children || [],
      };
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id);
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children;
      }
      // 加入到树中
      tree.push(child);
    }
  });
  return tree || [];
};

/**
 * 动态生成菜单, 从接口处获取路由列表
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = () => new Promise((resolve, reject) => {
  // 1、请求接口
  // loginService.getCurrentUserRoutes(token).then(res => {
  // const { data } = res;
  // const childrenNav = [];
  // // 后端数据, 根级树数组,  根级 PID
  // listToTree(data, childrenNav, -1);
  // const routers = convertRoutes(childrenNav);
  // routers.push(notFoundRouter);
  //     resolve(routers)
  // }).catch(err => {
  //     reject(err)
  // })

  // 2、本地调试数据
  const { data } = userRoute;
  const childrenNav = [];
  // 后端数据, 根级树数组,  根级 PID
  listToTree(data, childrenNav, -1);
  const routers = convertRoutes(childrenNav);
  routers.push(notFoundRouter);

  if (routers) {
    resolve(routers);
  } else {
    // eslint-disable-next-line prefer-promise-reject-errors
    reject({ code: 500, message: '错误' });
  }
});
