/**
 * @description 3个子配置，通用配置|主题配置|网络配置
 * 建议在当前目录下修改 config.js 自定义配置
 * 会覆盖默认配置，也可以直接修改默认配置
 */

// 默认配置
const setting = require('./default/setting');
const theme = require('./default/theme');
const network = require('./default/network');

// 自定义配置
const config = require('./config');

// 导出配置（以自定义配置为主）
module.exports = {
  ...theme,
  ...network,
  ...config,
  ...setting,
};
