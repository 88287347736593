import request from '@/utils/request';

export function logout() {
  return request({
    url: '/logout',
    method: 'post',
  });
}

// 登录
export function login(data) {
  return request({
    url: '/admin/v1/login',
    method: 'post',
    data,
  });
}

// 获取当前登录管理员
export function getUserInfo() {
  return request({
    url: '/admin/v1/admins/current',
    method: 'get',
  });
}

// 修改管理员个人基本信息
export function updateUserInfo(params) {
  return request({
    url: '/admin/v1/admins/current',
    method: 'put',
    data: params,
  });
}

// 修改管理员手机号
export function updateUserPhone(params) {
  return request({
    url: '/admin/v1/admins/current/phone',
    method: 'put',
    data: params,
  });
}

// 修改管理员邮箱
export function updateUserEmail(params) {
  return request({
    url: '/admin/v1/admins/current/email',
    method: 'put',
    data: params,
  });
}

// 修改管理员密码
export function updateUserPassword(params) {
  return request({
    url: '/admin/v1/admins/current/password',
    method: 'put',
    data: params,
  });
}

// 获取手机验证码
export function smsVerify(params) {
  return request({
    url: '/v1/sms/verify',
    method: 'post',
    data: params,
  });
}

// 获取邮件验证码
export function emailVerify(params) {
  return request({
    url: '/v1/email/verify',
    method: 'post',
    data: params,
  });
}
