import store from '@/store';

/**
 * <a-button v-if="$auth('form.edit')">Button</a-button>
 * @param Vue
 */
export default {
  install: (app, options) => {
    app.config.globalProperties.$auth = (permissions) => {
      // mock数据结构的写法，具体业务具体实现
      const [permission] = permissions.split('.');
      const permissionList = store.getters['user/roles'].permissions;
      return permissionList.find((val) => val === permission);
    };
    app.provide('auth', options);
  },
};
