import {
  DatePicker,
  Button,
  Layout,
  Menu,
  Dropdown,
  Avatar,
  Icon,
  Form,
  Input,
  Breadcrumb,
  ConfigProvider,
  Col,
  Tag,
  Row,
  Divider,
  Tooltip,
  Card,
  Statistic,
  Select,
  Table,
  Descriptions,
  Image,
  Steps,
  List,
  Tabs,
  Radio,
  Space,
  Pagination,
  Modal,
  Typography,
  Upload,
  Checkbox,
  InputNumber,
  message,
  Tree,
} from 'ant-design-vue';

message.config({
  maxCount: 3,
});

export default (app) => {
  app.use(ConfigProvider);
  app.use(Tree);
  app.use(InputNumber);
  app.use(Row);
  app.use(Col);
  app.use(DatePicker);
  app.use(Button);
  app.use(Layout);
  app.use(Menu);
  app.use(Dropdown);
  app.use(Avatar);
  app.use(Icon);
  app.use(Form);
  app.use(Tag);
  app.use(Breadcrumb);
  app.use(Divider);
  app.use(Tooltip);
  app.use(Input);
  app.use(Card);
  app.use(Statistic);
  app.use(Select);
  app.use(Table);
  app.use(Descriptions);
  app.use(Image);
  app.use(Steps);
  app.use(List);
  app.use(Tabs);
  app.use(Radio);
  app.use(Space);
  app.use(Pagination);
  app.use(Modal);
  app.use(Typography);
  app.use(Upload);
  app.use(Checkbox);
};
