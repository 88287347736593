/**
 * @description 自定义配置
 * */

const config = {
  layout: 'vertical',
  baseURL: '/api',
};

module.exports = config;
