<template>
    <a-config-provider :locale="locale">
        <router-view></router-view>
    </a-config-provider>
</template>

<script>
  import * as echarts from 'echarts';

  import { defineComponent, provide } from 'vue';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';

  export default defineComponent({
    name: 'App',
    setup() {
      provide('ec', echarts);
      return {
        locale: zhCN,
      };
    },
  });
</script>

<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }
    html, body {
        min-width: 1000px;
    }
</style>
