import { createStore } from 'vuex';

// 导入所有 vuex 模块
const files = require.context('./modules', false, /\.js$/);
const modules = {};
files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default;
});
Object.keys(modules).forEach((key) => {
  // 解决vuex命名冲突
  modules[key].namespaced = true;
});

export default createStore({
  modules,
});
